import * as React from 'react';
import { createRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import TestResult from "../../components/test-result";


const CMPPage = () => {
  const [result, setResult] = useState(null);
  const cmpRef = createRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!cmpRef?.current) {
        return;
      }

      const styles = window.getComputedStyle(cmpRef.current);
      if (styles.getPropertyValue('display') === 'none') {
        setResult(true);
        clearInterval(intervalId);
      } else {
        setResult(false);
      }
    }, 150);
  }, [cmpRef]);

  return (
    <Layout>
      <h1>Cookie Consent Popups</h1>
      <p>
        This page shows page content that should be hidden only when the user has
        enabled <u>Hide Cookie Consent Popups</u>. With Crumbs this is done by
        opening <code>/options.html?path=settings</code> and ensure this feature is activated and
        refresh the page.
      </p>

      <div className="flex mb-10">
        <div className="w-1/2">
          <h3>Control Case</h3>
          <div className="border-2 border-gray-300 p-5">
            <div>
              This is a normal text.
            </div>
          </div>
          <p>You should see the above text.</p>
        </div>
        <div className="flex-none w-1"/>
        <div className="w-1/2">
          <h3>Test Case</h3>
          <TestResult result={result}>
            <div className="border-2 p-5">
              <div id="EUCookieBar" ref={cmpRef}>
                This is a Cookie Notice message.
              </div>
            </div>
          </TestResult>
          <p>You should <b>NOT</b> see the above text.</p>
        </div>
      </div>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Cookie Consent Popups"/>
export default CMPPage;
