/**
 * TestResult component stores the test result data for mobile JS query
 */

import * as React from "react"
import PropTypes from "prop-types"

const TestResult = ({ result, colorStyle = "border", children }) => {
  const status = result === false ? "Failed" : result === true ? "Passed" : "InProgress";

  let classes = "TestResult";
  if (colorStyle) {
    const color = result === false ? "red-500" : result === true ? "green-500" : "gray-500";
    classes = `${classes} ${colorStyle}-${color}`
  }

  return React.Children.map(children, child =>
    React.cloneElement(child, {
      className: `${child.props.className} ${classes}`,
      'data-result': status
    })
  );
}

TestResult.propTypes = {
  result: PropTypes.bool,
  children: PropTypes.node,
}

export default TestResult
